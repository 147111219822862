<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-profile d-table w-100" style="background: url('/images/company/admission.jpeg') center center;">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-custom rounded shadow mb-0">
                    <li class="breadcrumb-item"><a href="#" class="text_custom">Admission Now</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <!-- Hero End -->

    <!-- Job apply form Start -->
    <section class="section">
      <div class="container">
        <div class="row justify-content-center text_custom">
          <div v-if="is_payment" class="col-lg-12 col-md-12  col-sm-12">
            <div class="card custom-form border-0">
              <div class="card-body">
                <h4>Payment Information</h4>
                <div v-if="error"  class="alert alert-danger" role="alert">
                  <center>{{ error }}</center>
                </div>
                <h6>Applicant's name: <b>{{ap_name}}</b></h6>
                  <h6>Track No        : <b>{{track_no}}</b></h6>
                  <h6>Payable Amount  : <b>{{amount}}</b></h6>
                <button class="btn btn-primary btn-lg btn-block"
                        id="sslczPayBtn" @click="sslCommerze()" type="submit">Pay Now</button>
<!--                <button class="btn btn-primary btn-lg btn-block" id="sslczPayBtn"
                        postdata="your javascript arrays or objects which requires in backend"
                        :endpoint="ull()"> Pay Now
                </button>-->
              </div>
            </div><!--end custom-form-->
          </div>
          <div v-else class="col-lg-12 col-md-12  col-sm-12">
            <div class="card custom-form border-0">
              <div class="card-body">
                <h4>Fill Your Personal Info</h4>
                <div v-if="error"  class="alert alert-danger" role="alert">
                  <center>{{ error }}</center>
                </div>
<!--                <validation-observer ref="simpleRules">-->
                  <form class="rounded shadow p-4" ref="admission_form" action="javascript:void(0)" method="POST" @submit="admissionSubmit()">
                    <div class="row">
                      
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3 form-group">
                          <label class="form-label">Applicant's Name:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="user" class="fea icon-sm icons"></i>-->
                            <input  name="name" id="applicant-name" required type="text" class="form-control ps-5" placeholder="As per birth & psc certificate" v-model="name">
                            <small v-if="errorFind('name')" class="text-danger">{{ errorFind('name').value }}</small>
                          </div>
                        </div>
                      </div><!--end col-->
                     
                      <!--end col-->
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Date of Birth:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="calendar" class="fea icon-sm icons"></i>-->
                            <input name="date_of_birth" required v-model="date_of_birth" id="date-of-birth" type="date" class="form-control ps-5" placeholder="As per birth certificate">
                          </div>
                          <small v-if="errorFind('date_of_birth')" class="text-danger">{{ errorFind('date_of_birth').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-6 col-lg-4 col-sm-6">
                        <div class="mb-3">
                          <label class="form-label">Class:<span class="text-danger">*</span></label>
                            <select class="form-control" aria-label="Classes Id" name="classes_id" v-model="classes_id" required>
                              <option :value="null" >Select Class</option>
                              <option v-for="(cls,key) in classes" :value="cls.id" :key="key">{{ cls.name }}</option>
                            </select>
                          <small v-if="errorFind('classes_id')" class="text-danger">{{ errorFind('classes_id').value }}</small>
                        </div>
                      </div>

                      <div class="col-md-6 col-lg-4 col-sm-6">
                        <div class="mb-3">
                          <label class="form-label">Wing:<span class="text-danger">*</span></label>
                          <select class="form-control" aria-label="Wing Id" name="wing_id" v-model="wing_id" required>
                            <option :value="null" selected>Select Wing</option>
                            <option v-for="(cls,key) in wings" :value="cls.id" :key="key">{{ cls.name }}</option>
                          </select>
                          <small v-if="errorFind('wing_id')" class="text-danger">{{ errorFind('wing_id').value }}</small>
                        </div>
                      </div>

                      <div class="col-md-6 col-lg-4 col-sm-6">
                        <div class="mb-3">
                          <label class="form-label">Version:<span class="text-danger">*</span></label>
                          <select class="form-control" aria-label="Version Id" name="version_id" v-model="version_id" required>
                            <option :value="null" selected>Select Version</option>
                            <option v-for="(cls,key) in versions" :value="cls.id" :key="key">{{ cls.name }}</option>
                          </select>
                          <small v-if="errorFind('version_id')" class="text-danger">{{ errorFind('version_id').value }}</small>
                        </div>
                      </div>

                      <div class="col-md-6 col-lg-4 col-sm-6">
                        <div class="mb-3">
                          <label class="form-label">Student Group:<span class="text-danger">*</span></label>
                          <select class="form-control" aria-label="Student Group Id" name="student_group_id" v-model="student_group_id" required>
                            <option :value="null" selected>Select Student Group</option>
                            <option v-for="(cls,key) in student_groups" :value="cls.id" :key="key">{{ cls.name }}</option>
                          </select>
                          <small v-if="errorFind('student_group_id')" class="text-danger">{{ errorFind('student_group_id').value }}</small>
                        </div>
                      </div>

                      <div class="col-md-6 col-lg-4 col-sm-6">
                        <div class="mb-3">
                          <label class="form-label">Academic Year:<span class="text-danger">*</span></label>
                          <select class="form-control" aria-label="Academic Year Id" name="academic_year_id" v-model="academic_year_id" required>
                            <option :value="null" selected>Select Academic Year</option>
                            <option v-for="(cls,key) in academic_years" :value="cls.id" :key="key">{{ cls.year }}</option>
                          </select>
                          <small v-if="errorFind('academic_year_id')" class="text-danger">{{ errorFind('academic_year_id').value }}</small>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4 col-sm-6">
                        <div class="mb-3">
                          <label class="form-label">Blood Group:</label>
                          <select class="form-control" aria-label="Blood Group Id" name="blood_group_id" v-model="blood_group_id" >
                            <option :value="null" selected>Select Blood Group</option>
                            <option v-for="(cls,key) in blood_groups" :value="cls.id" :key="key">{{ cls.name }}</option>
                          </select>
                          <small v-if="errorFind('blood_group_id')" class="text-danger">{{ errorFind('blood_group_id').value }}</small>
                        </div>
                      </div>
                      
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Birth Certificate Reg No.</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="hash" class="fea icon-sm icons"></i>-->
                            <input name="birth_reg_no"  v-model="birth_reg_no" id="birth_reg_no" type="text" class="form-control ps-5" placeholder="Birth Certificate Reg No.">
                          </div>
                          <small v-if="errorFind('birth_reg_no')" class="text-danger">{{ errorFind('birth_reg_no').value }}</small>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label">Height (In cm):<span class="text-danger" >*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="activity" class="fea icon-sm icons"></i>-->
                            <input name="height" v-model="height" id="feet" required type="text" class="form-control ps-5" placeholder="height">
                          </div>
                          <small v-if="errorFind('height')" class="text-danger">{{ errorFind('height').value }}</small>
                        </div>
                      </div><!--end col-->
                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label">Weight (In KG):<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="activity" class="fea icon-sm icons"></i>-->
                            <input name="weight" v-model="weight" id="weight" type="text" class="form-control ps-5" placeholder="Weight">
                          </div>
                          <small v-if="errorFind('weight')" class="text-danger">{{ errorFind('weight').value }}</small>
                        </div>
                      </div><!--end col-->
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Nationality:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="bookmark" class="fea icon-sm icons"></i>-->
                            <input name="nationality" v-model="nationality" required id="nationality" type="text" class="form-control ps-5" placeholder="Bangladeshi">
                          </div>
                          <small v-if="errorFind('nationality')" class="text-danger">{{ errorFind('nationality').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label">Gender:<span class="text-danger">*</span></label>
                          <select class="form-control" name="gender_id" required v-model="gender_id" id="Sortbylist-Shop">
                            <option :value="null" selected>Select One</option>
                            <option v-for="(cls,key) in genders" :value="cls.id" :key="key">{{ cls.name }}</option>
                          </select>
                          <small v-if="errorFind('gender_id')" class="text-danger">{{ errorFind('gender_id').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-3">
                        <div class="mb-3">
                          <label class="form-label">Religion:<span class="text-danger">*</span></label>
                          <select class="form-control" name="religion_id" required v-model="religion_id" id="religion">
                            <option :value="null" selected>Select One</option>
                            <option v-for="(cls,key) in religions" :value="cls.id" :key="key">{{ cls.name }}</option>
                          </select>
                          <small v-if="errorFind('religion_id')" class="text-danger">{{ errorFind('religion_id').value }}</small>
                        </div>
                      </div><!--end col-->
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Previous School Name:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="home" class="fea icon-sm icons"></i>-->
                            <input name="previous_school_name" v-model="previous_school_name" required id="previous_school_name" type="text" class="form-control ps-5" placeholder="Previous School Name">
                          </div>
                          <small v-if="errorFind('previous_school_name')" class="text-danger">{{ errorFind('previous_school_name').value }}</small>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Previous Medium:<span class="text-danger">*</span></label>
                          <select class="form-control" aria-label="Medium Id" name="medium" v-model="medium_id" required>
                            <option :value="null" selected>Select Medium</option>
                            <option v-for="(cls,key) in mediums" :value="cls.id" :key="key">{{ cls.name }}</option>
                          </select>
                          <small v-if="errorFind('medium')" class="text-danger">{{ errorFind('medium').value }}</small>
                        </div>
                      </div>
                      <!--end col-->
                      
                      <!--end col-->
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Phone Number(For SMS):<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="briefcase" class="fea icon-sm icons"></i>-->
                            <input name="guardian_mobile" v-model="guardian_mobile"  type="text" class="form-control ps-5" placeholder="8801" required>
                          </div>
                          <small v-if="errorFind('guardian_mobile')" class="text-danger">{{ errorFind('guardian_mobile').value }}</small>
                        </div>
                      </div><!--end col-->
                    </div><!--end row-->
                    <div class="row">
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Father's Name:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="user-check" class="fea icon-sm icons"></i>-->
                            <input name="father_name" v-model="father_name" id="father-name" type="text" class="form-control ps-5" required placeholder="Father name">
                          </div>
                          <small v-if="errorFind('father_name')" class="text-danger">{{ errorFind('father_name').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Father's Nationality:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="bookmark" class="fea icon-sm icons"></i>-->
                            <input name="father_nationality" v-model="father_nationality" id="father_nationality" required type="text" class="form-control ps-5" placeholder="Father's Nationality">
                          </div>
                          <small v-if="errorFind('father_nationality')" class="text-danger">{{ errorFind('father_nationality').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">

                           <label class="form-label">Parental Occupation:<span class="text-danger">*</span></label>
                          <select class="form-control" name="occupation_type" required v-model="occupation_type" id="occupation_type">
                            <option :value="null" selected>Select One</option>
                            <option value="1">Army(Officer)</option>
                             <option value="2">Army(AFNS)</option>
                            <option value="3">Army(JCO & OR)</option>
                            <option value="16">Army(Retd)</option>
                            <option value="4">Navy</option>
                            <option value="5">Airforce</option>
                            <option value="6">Police</option>
                            <option value="7">Govt. Official</option>
                            <option value="8">Doctor</option>
                            <option value="9">Teacher</option>
                            <option value="10">Lawer</option>
                            <option value="11">Business</option>
                             <option value="12">Housewife</option>
                            <option value="13">Contractor</option>
                            <option value="14">Service</option>
                            <option value="15">Others</option>
                          </select>
                          <small v-if="errorFind('occupation_type')" class="text-danger">{{ errorFind('occupation_type').value }}</small>

                        </div>
                      </div>


                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Parent's Designation:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="briefcase" class="fea icon-sm icons"></i>-->
                            <input name="father_occupations" v-model="father_occupations" required id="fathers-profession" type="text" class="form-control ps-5" placeholder="Office/Company, Post">
                          </div>
                          <small v-if="errorFind('father_occupations')" class="text-danger">{{ errorFind('father_occupations').value }}</small>
                        </div>
                      </div><!--end col-->
                      
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Father's Nid:</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="credit-card" class="fea icon-sm icons"></i>-->
                            <input name="father_nid" v-model="father_nid" id="father_nid" type="text" class="form-control ps-5" placeholder="Father's Nid">
                          </div>
                          <small v-if="errorFind('father_nid')" class="text-danger">{{ errorFind('father_nid').value }}</small>
                        </div>
                      </div><!--end col-->

                       <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Father's Mobile Number:</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="phone" class="fea icon-sm icons"></i>-->
                            <input name="father_phone_number" v-model="father_phone_number" id="father-monthly-income" type="text" class="form-control ps-5"  placeholder="(+880)">
                          </div>
                          <small v-if="errorFind('father_phone_number')" class="text-danger">{{ errorFind('father_phone_number').value }}</small>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Mother's Name:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="user-check" class="fea icon-sm icons"></i>-->
                            <input name="mother_name" v-model="mother_name" id="mother-name" required type="text" class="form-control ps-5" placeholder="As per birth certificate">
                          </div>
                          <small v-if="errorFind('mother_name')" class="text-danger">{{ errorFind('mother_name').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Mother's Nationality:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="bookmark" class="fea icon-sm icons"></i>-->
                            <input name="mother_nationality" v-model="mother_nationality" required id="mother_nationality" type="text" class="form-control ps-5" placeholder="Mother's Nationality">
                          </div>
                          <small v-if="errorFind('mother_nationality')" class="text-danger">{{ errorFind('mother_nationality').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Mother's Nid:</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="credit-card" class="fea icon-sm icons"></i>-->
                            <input name="mother_nid" v-model="mother_nid" id="mother_nid" type="text" class="form-control ps-5" placeholder="Mother's Nid">
                          </div>
                          <small v-if="errorFind('mother_nid')" class="text-danger">{{ errorFind('mother_nid').value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Mother's Profession:</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="briefcase" class="fea icon-sm icons"></i>-->
                            <input name="mother_occupations" v-model="mother_occupations" id="mothers-profession" type="text" class="form-control ps-5" placeholder="Office/Company, Post">
                          </div>
                          <small v-if="errorFind('mother_occupations')" class="text-danger">{{ errorFind('mother_occupations').value }}</small>
                        </div>
                      </div><!--end col-->
                      
                      
                     
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Mother's Mobile Number:</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="phone" class="fea icon-sm icons"></i>-->
                            <input name="mother_phone_number" v-model="mother_phone_number"  id="mothers-monthly-income" type="text" class="form-control ps-5"  placeholder="(+880)">
                          </div>
                          <small v-if="errorFind('mother_phone_number')" class="text-danger">{{ errorFind('mother_phone_number').value }}</small>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Parent's Monthly Income:<span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="dollar-sign" class="fea icon-sm icons"></i>-->
                            <input name="monthly_income" required v-model="monthly_income" placeholder="Father's Monthly Income" id="fathers-monthly-income" type="text" class="form-control ps-5">
                          </div>
                          <small v-if="errorFind('monthly_income')" class="text-danger">{{ errorFind('monthly_income').value }}</small>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="row">
                          
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label class="form-label">Permanent Address:<span class="text-danger">*</span></label>
                              <div class="form-icon position-relative">
<!--                                <i data-feather="map-pin" class="fea icon-sm icons"></i>-->
                                <input name="permanent_address" placeholder="Give your permanent address" required v-model="permanent_address" id="permanent-address" type="text" class="form-control ps-5">
                              </div>
                              <small v-if="errorFind('permanent_address')" class="text-danger">{{ errorFind(permanent_address).value }}</small>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <select class="form-control custom-select" id="select1" name="pm_division_id" v-model="pm_division_id" required>
                                    <option :value="null" selected>Select Division</option>
                                    <option v-for="(div,key) in pm_divisions" :key="key" :value="div.id">{{ div.name }}</option>
                                  </select>
                                  <small v-if="errorFind('pm_division_id')" class="text-danger">{{ errorFind(pm_division_id).value }}</small>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <select class="form-control custom-select" id="select2" name="pm_district_id" v-model="pm_district_id" required>
                                    <option :value="null" selected>Select Districts</option>
                                    <option v-for="(div,key) in pm_districts" :key="key" :value="div.id">{{ div.name }}</option>
                                  </select>
                                  <small v-if="errorFind('pm_district_id')" class="text-danger">{{ errorFind(pm_district_id).value }}</small>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <select class="form-control custom-select" id="select3" name="pm_thana_upozila_id" required v-model="pm_thana_upozila_id">
                                    <option :value="null" selected>Select Thana/Upazila</option>
                                    <option v-for="(div,key) in pm_thana_upozilas" :key="key" :value="div.id">{{ div.name }}</option>
                                  </select>
                                  <small v-if="errorFind('pm_thana_upozila_id')" class="text-danger">{{ errorFind(pm_thana_upozila_id).value }}</small>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div><!--end col-->
                      <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="row">
                          
                          <div class="col-md-12">
                            <div class="mb-3">
                              <label class="form-label">Present Address:<span class="text-danger">*</span></label>
                              <div class="form-icon position-relative">
<!--                                <i data-feather="map-pin" class="fea icon-sm icons"></i>-->
                                <input name="present_address" required v-model="present_address" placeholder="Give your present address" id="present-address" type="text" class="form-control ps-5">
                              </div>
                              <small v-if="errorFind('present_address')" class="text-danger">{{ errorFind(present_address).value }}</small>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <select class="form-control custom-select" name="division_id" v-model="division_id" required>
                                    <option :value="null" selected>Select Division</option>
                                    <option v-for="(div,key) in ps_divisions" :key="key" :value="div.id">{{ div.name }}</option>
                                  </select>
                                  <small v-if="errorFind('division_id')" class="text-danger">{{ errorFind(division_id).value }}</small>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <select class="form-control custom-select"  name="district_id" v-model="district_id" required>
                                    <option :value="null" selected>Select Districts</option>
                                    <option v-for="(div,key) in ps_districts" :key="key" :value="div.id">{{ div.name }}</option>
                                  </select>
                                  <small v-if="errorFind('district_id')" class="text-danger">{{ errorFind(district_id).value }}</small>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="mb-3">
                                  <select class="form-control custom-select"  name="thana_upozila_id" v-model="thana_upozila_id" required>
                                    <option :value="null" selected>Select Thana/Upazila</option>
                                    <option v-for="(div,key) in ps_thana_upozilas" :key="key" :value="div.id">{{ div.name }}</option>
                                  </select>
                                  <small v-if="errorFind('thana_upozila_id')" class="text-danger">{{ errorFind(thana_upozila_id).value }}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Legal Guardian's Name & Relation (In absence of Parents):</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="user" class="fea icon-sm icons"></i>-->
                            <input name="guardian_name" id="guardians-name" type="text" class="form-control ps-5" placeholder="Guardian's Name & Relation">
                          </div>
                          <small v-if="errorFind('guardian_name')" class="text-danger">{{ errorFind(guardian_name).value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Guardian's Occupation :</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="briefcase" class="fea icon-sm icons"></i>-->
                            <input name="guardians_occupation" placeholder="guardians occupation" id="guardians-occupation" type="text" class="form-control ps-5">
                          </div>
                          <small v-if="errorFind('guardians_occupation')" class="text-danger">{{ errorFind(guardians_occupation).value }}</small>
                        </div>
                      </div>
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Guardian's Phone / Mobile No:</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="phone" class="fea icon-sm icons"></i>-->
                            <input name="guardian_phone" v-model="guardian_phone" id="guardians-phone" type="text" class="form-control ps-5" placeholder="(+880)">
                          </div>
                          <small v-if="errorFind('guardian_phone')" class="text-danger">{{ errorFind(guardian_phone).value }}</small>
                        </div>
                      </div>

                        <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="mb-3">
                          <label class="form-label">Guardian's Address:</label>
                          <div class="form-icon position-relative">
<!--                            <i data-feather="map-pin" class="fea icon-sm icons"></i>-->
                            <input name="guardian_address" placeholder="Guardian's Address" id="guardians-permanent-address" type="text" class="form-control ps-5">
                          </div>
                          <small v-if="errorFind('guardian_address')" class="text-danger">{{ errorFind(guardian_address).value }}</small>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="row">
                          <div class="col-md-4">
                            <div class="mb-3">
                              <select class="form-control custom-select"  name="g_division_id" v-model="g_division_id" >
                                <option :value="null" selected>Select Division</option>
                                <option v-for="(div,key) in g_divisions" :key="key" :value="div.id">{{ div.name }}</option>
                              </select>
                              <small v-if="errorFind('g_division_id')" class="text-danger">{{ errorFind(g_division_id).value }}</small>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="mb-3">
                              <select class="form-control custom-select"  name="g_district_id"  v-model="g_district_id">
                                <option :value="null" selected>Select Districts</option>
                                <option v-for="(div,key) in g_districts" :key="key" :value="div.id">{{ div.name }}</option>
                              </select>
                              <small v-if="errorFind('g_district_id')" class="text-danger">{{ errorFind(g_district_id).value }}</small>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="mb-3">
                              <select class="form-control custom-select" name="g_thana_upozila_id"  v-model="g_thana_upozila_id">
                                <option :value="null" selected>Select Thana/Upazila</option>
                                <option v-for="(div,key) in g_thana_upozilas" :key="key" :value="div.id">{{ div.name }}</option>
                              </select>
                              <small v-if="errorFind('g_thana_upozila_id')" class="text-danger">{{ errorFind(g_thana_upozila_id).value }}</small>
                            </div>
                          </div>
                        </div>

                      </div><!--end col-->
                     
                      
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Student Photo :<span class="text-danger">*</span></label>
                          <input class="form-control" required name="student_image" type="file" id="formFile">
                          <small v-if="errorFind('student_image')" class="text-danger">{{ errorFind(student_image).value }}</small>
                        </div>
                      </div><!--end col-->

                       <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Applicant's Sign (jpg,png,jpeg):(300X100)px<span class="text-danger">*</span></label>
                          <input class="form-control" name="applicant_sign" id="applicant_sign" type="file" required>
                          <small v-if="errorFind('applicant_sign')" class="text-danger">{{ errorFind('applicant_sign').value }}</small>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Father Photo (jpg,png,jpeg):</label>
                          <input class="form-control" name="father_image" type="file" id="formFile1">
                          <small v-if="errorFind('father_image')" class="text-danger">{{ errorFind(father_image).value }}</small>
                        </div>
                      </div><!--end col-->
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Mother Photo (jpg,png,jpeg):</label>
                          <input class="form-control" name="mother_image" type="file" id="formFile2">
                          <small v-if="errorFind('mother_image')" class="text-danger">{{ errorFind(mother_image).value }}</small>
                        </div>
                      </div><!--end col-->
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Birth Certificate (jpg,png,jpeg):</label>
                          <input class="form-control" name="gurdian_image" type="file" id="formFile3">
                          <small v-if="errorFind('gurdian_image')" class="text-danger">{{ errorFind(gurdian_image).value }}</small>
                        </div>
                      </div><!--end col-->

                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Last Academic Certificate(jpg,png,jpeg):<span class="text-danger">*</span></label>
                          <input class="form-control" name="last_academic_certificate" id="last_academic_certificate" type="file" required>
                          <small v-if="errorFind('last_academic_certificate')" class="text-danger">{{ errorFind('last_academic_certificate').value }}</small>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-md-12 col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="formFile" class="form-label">Last Academic Testimonial(jpg,png,jpeg):<span class="text-danger">*</span></label>
                          <input class="form-control" name="last_academic_testimonial" id="last_academic_testimonial" type="file" required>
                          <small v-if="errorFind('last_academic_testimonial')" class="text-danger">{{ errorFind('last_academic_testimonial').value }}</small>
                        </div>
                      </div>

                      <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="mb-3">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" required>
                            <label class="form-check-label" for="flexCheckDefault">I am hereby certify that, above mentioned information are corrected to the best of my knowledge.</label>
                          </div>
                        </div>
                      </div><!--end col-->
                    </div><!--end row-->
                    <div class="row">
                      <div class="col-sm-12">
  <!--                      <router-link :to="{name:'applyonlinesecond'}" exact><input type="submit" id="submit" name="send" class="submitBnt btn btn-primary" value="Next"></router-link>
                      &lt;!&ndash;end col&ndash;&gt;-->
                        <input type="submit" id="submit" name="send" class="submitBnt btn btn-success" value="Apply"/>
                      </div>
                    </div><!--end row-->
                  </form><!--end form-->
<!--                </validation-observer>-->
              </div>
            </div><!--end custom-form-->
          </div>
        </div>
      </div><!--end container-->
    </section><!--end section-->
    <!-- Job apply form End -->

    <!-- Profile End -->
  </div>
</template>
<!--all id field, dob field will be null-->
<script>
import feather from 'feather-icons';
import apiCall from "../../../core/apiCall";
//import { ValidationProvider, ValidationObserver } from 'vee-validate'
//import {required,integer} from '@validations'
export default {
  name: 'ApplicationForm',
  components: {
    //ValidationProvider, ValidationObserver
  } ,
  mounted() {
    feather.replace();
  },
  data(){
    return {
      error:'',
      is_payment:false,
      ap_name:'',
      track_no:null,
      amount:0,
      //required,integer,
      errors:[],
      name:'',
      birth_reg_no:'',
      classes_id:null,
      student_image:'',
      date_of_birth:'',
      nationality:'',
      height:'',
      weight:'',
      father_name:'',
      mother_name:'',
      father_phone_number:'',
      mother_phone_number:'',
      monthly_income:'',
      present_address:'',
      father_occupations:'',
      mother_occupations:'',
      guardians:'',
      payment_amount:'',
      is_paid:'',
      father_image:'',
      mother_image:'',
      gurdian_image:'',
      guardian_phone:'',
      guardian_mobile:'8801',
      version_id:null,
      wing_id:null,
      student_group_id:null,
      academic_year_id:null,
      gender_id:null,
      religion_id:null,
      occupation_type :null,
      blood_group_id:null,
      medium_id:null,
      mother_nid:'',
      mother_nationality:'',
      father_nationality:'',
      father_nid:'',
      applicant_sign:'',
      previous_school_name:'',
      last_academic_certificate:'',
      last_academic_testimonial:'',
      permanent_address:'',
      classes:[],
      versions:[],
      wings:[],
      student_groups:[],
      academic_years:[],
      genders:[],
      blood_groups:[],
      religions:[],
      mediums:[
        {id:1,name:'Bangla'},
        {id:2,name:'English'},
      ],
      divisions:[],

      ps_divisions:[],
      ps_districts:[],
      ps_thana_upozilas:[],
      division_id:null,
      district_id:null,
      thana_upozila_id:null,

      pm_divisions:[],
      pm_districts:[],
      pm_thana_upozilas:[],
      pm_division_id:null,
      pm_district_id:null,
      pm_thana_upozila_id:null,

      g_divisions:[],
      g_districts:[],
      g_thana_upozilas:[],
      g_division_id:null,
      g_district_id:null,
      g_thana_upozila_id:null,
      is_submit:false,
    }
  },
  created() {
    this.getBasicData();
  },
  methods:{
    ull(){
      return process.env.VUE_APP_ENV_API_URL+'/pay-via-ajax';
    },
    async getBasicData(){
      await apiCall.get('/admission/form/common/information').then((response)=>{
          let dt =response.data;
          if(dt){
            this.classes=dt.classes;
            this.academic_years=dt.academic_years;
            this.genders=dt.genders;
            this.blood_groups=dt.blood_groups;
            this.religions=dt.religions;
            this.divisions=dt.divisions;
          }
      }).catch(()=>{
        this.classes=[];
        this.academic_years=[];
        this.genders=[];
        this.blood_groups=[];
        this.religions=[];
        this.divisions=[];
      })
    },
    classChange(){
      this.version_id=null;
      this.wing_id=null;
      this.student_group_id=null;
      this.versions=[];
      this.wings=[];
      this.student_groups=[];
      if(this.classes_id){
        this.versions=this.classes.find(item=>item.id===this.classes_id)?this.classes.find(item=>item.id===this.classes_id).versions:[];
        this.wings=this.classes.find(item=>item.id===this.classes_id)?this.classes.find(item=>item.id===this.classes_id).wings:[];
        this.student_groups=this.classes.find(item=>item.id===this.classes_id)?this.classes.find(item=>item.id===this.classes_id).student_groups:[];
      }
    },
    divChange(){
      this.district_id=null;
      this.thana_upozila_id=null;
      this.ps_districts=[];
      this.ps_thana_upozilas=[];
      if(this.division_id){
        this.ps_districts=this.ps_divisions.find(item=>item.id===this.division_id)?this.ps_divisions.find(item=>item.id===this.division_id).districts:[];
      }
    },
    psDisChange(){
      this.thana_upozila_id=null;
      this.ps_thana_upozilas=[];
      if(this.district_id){
        this.ps_thana_upozilas=this.ps_districts.find(item=>item.id===this.district_id)?this.ps_districts.find(item=>item.id===this.district_id).thana_upozilas:[];
      }
    },
    pmDivChange(){
      this.pm_district_id=null;
      this.pm_thana_upozila_id=null;
      this.pm_districts=[];
      this.pm_thana_upozilas=[];
      if(this.pm_division_id){
        this.pm_districts=this.pm_divisions.find(item=>item.id===this.pm_division_id)?this.pm_divisions.find(item=>item.id===this.pm_division_id).districts:[];
      }
    },
    pmDisChange(){
      this.pm_thana_upozila_id=null;
      this.pm_thana_upozilas=[];
      if(this.pm_district_id){
        this.pm_thana_upozilas=this.pm_districts.find(item=>item.id===this.pm_district_id)?this.pm_districts.find(item=>item.id===this.pm_district_id).thana_upozilas:[];
      }
    },
    gDivChange(){
      this.g_district_id=null;
      this.g_thana_upozila_id=null;
      this.g_districts=[];
      this.g_thana_upozilas=[];
      if(this.g_division_id){
        this.g_districts=this.g_divisions.find(item=>item.id===this.g_division_id)?this.g_divisions.find(item=>item.id===this.g_division_id).districts:[];
      }
    },
    gDisChange(){
      this.g_thana_upozila_id=null;
      this.g_thana_upozilas=[];
      if(this.g_district_id){
       this.g_thana_upozilas=this.g_districts.find(item=>item.id===this.g_district_id)?this.g_districts.find(item=>item.id===this.g_district_id).thana_upozilas:[];
      }
    },
    async admissionSubmit(){
      this.error='';
      this.errors=[];
      let data =new FormData(this.$refs.admission_form);
      if(this.is_submit == false){
        this.is_submit=true;
        await apiCall.post('/admission/information/store',data).then((response)=>{
          this.is_submit=false;
          let dt=response.data.data;
          this.ap_name=dt.name;
          this.track_no=dt.track_no;
          this.amount=dt.amount;
          this.is_payment=true;
          this.$refs.admission_form.reset();
        }).catch((error)=>{
          this.is_submit=false;
          if (error.response.status ===422) {
            Object.keys(error.response.data.errors).map((field) => {
              this.errors.push({name:field,value: error.response.data.errors[field][0]})
            })
          }
          else this.error="Invalid Request try again";
        })
      }

    },
    errorFind(name){
      return this.errors.find(item=>item.name===name);
    },
    async sslCommerze(){
      this.error='';
      let data=new FormData();
      data.append('track_no', this.track_no);
      data.append('amount', this.amount);
      if(this.is_submit == false) {
         this.is_submit=true;
        await apiCall.post('/pay-via-ajax', data).then((response) => {
          this.is_submit= false;
          if (response.data.status === 'success') {
            window.location = response.data.data;
          } else if (response.data.status === 'custom') {
            this.error = response.data.message;
          }
        }).catch(() => {
          this.is_submit= false;
          this.error = "The request is invalid. Please try again after sometimes.";
        })
      }
    },
  },
  watch: {
    classes(){
      this.classes_id=null;
      this.classChange();
    },
    classes_id(){
      this.classChange();
    },
    divisions(){
      this.division_id=null;
      this.pm_division_id=null;
      this.g_division_id=null;
      this.ps_divisions=this.divisions;
      this.pm_divisions=this.divisions;
      this.g_divisions=this.divisions;
    },
    ps_divisions(){
      this.divChange();
    },
    ps_districts(){
      this.psDisChange();
    },
    district_id(){
      this.psDisChange();
    },
    division_id(){
      this.divChange();
    },
    pm_divisions(){
      this.pmDivChange();
    },
    pm_division_id(){
      this.pmDivChange();
    },
    pm_districts(){
      this.pmDisChange();
    },
    pm_district_id(){
      this.pmDisChange();
    },
    g_divisions(){
      this.gDivChange();
    },
    g_division_id(){
      this.gDivChange();
    },
    g_districts(){
      this.gDisChange();
    },
    g_district_id(){
      this.gDisChange();
    },
  }
}
</script>
<style scoped>
@import './../../../assets/css/style-dark.css';
</style>